import React, { useState } from "react";
import axios from "axios";
import brainFoundationLogo from "./brain_logo_Large.png";

export default function Home() {
  const [question, setQuestion] = useState("");
  const [response, setResponse] = useState("");
  const [sources, setSources] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    setQuestion(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // const res = await axios.get(`https://api.askbrainfoundation.org/answerQuestion?query=${encodeURIComponent(question)}`);
      // console.log('res: ', res);
      const res = await axios.get(
        `https://brainfoundationchatbot-dawn-rain-9994.fly.dev/answerQuestion/?query=${encodeURIComponent(
          question
        )}`
      );
      console.log("res: ", res);
      const responseData = res.data;
      setResponse(responseData.response_text);
      console.log("Response Sources ----> ", responseData.sources);
      setSources(responseData.sources || []);
    } catch (error) {
      console.error("Error fetching the answer:", error);
      setResponse(
        "There was an error fetching the answer. Please try again later."
      );
      setSources([]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="flex flex-col h-screen justify-between w-full isolate overflow-hidden bg-gray-900 py-8 sm:py-8 lg:py-8">
      <div className="w-full items-center">
        {
          <p className="mt-4 sm:text-sm md:text-lg leading-8 text-gray-300 mx-5">
            Search the content and presentations sponsored by The BRAIN
            Foundation
          </p>
        }
        {/* <div
          className="bg-yellow-500 text-white text-center p-4 shadow-lg fixed top-0 left-0 w-full z-50">
          The chatbot is currently under maintenance. Please visit us later.
        </div> */}
        <form
          onSubmit={handleSubmit}
          className="mt-6 mx-auto flex max-w-8xl justify-around gap-x-4 px-10 md:px-60 lg:px-80"
        >
          <label htmlFor="question" className="sr-only">
            Ask me a question
          </label>
          <input
            id="question"
            name="text"
            type="text"
            autoComplete="text"
            required
            className="min-w-0 flex-auto rounded-md text-sm border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
            placeholder="Are seizures more common in ASD?"
            value={question}
            onChange={handleInputChange}
          />
          <button
            type="submit"
            className="flex-none rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          >
            {loading ? "Loading..." : "Search"}
          </button>
        </form>
        {/* <p className="mt-4 px-80 text-xs leading-6 text-gray-300">This AI powered search engine helps you locate and find information from the BRAIN Foundation website and video content. Please note that this product is in beta and will continue to improve. This product is meant to provide easier access to information for the purpose of education and discussion. It does not provide medical advice. Please consult with your physicians for medical care.</p> */}
        <div className="w-full px-10 md:px-60 lg:px-80">
          {loading ? (
            <div className="flex justify-center items-center h-60">
              <div className="spinner w-16 h-16 border-4 border-dashed rounded-full"></div>
            </div>
          ) : (
            response && (
              <div className="mt-4 mx-auto max-h-60 text-left overflow-auto scroll-smooth rounded-md border-1 px-10 py-5 text-slate-400 shadow-sm text-sm md:text-base sm:leading-6 ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                <p>{response}</p>
              </div>
            )
          )}
        </div>
        <div className="w-full px-10 md:px-60 lg:px-80 max-h-100 overflow-auto scroll-smooth">
          {/* <div className="max-h-4 overflow-auto scroll-smooth"> */}
            {sources &&
              sources.links &&
              sources.links.length > 0 &&
              sources.links.map((link, index) => {
                // Check if the link is a YouTube link
                const isYouTube =
                  link.includes("youtube.com/watch?v=") ||
                  link.includes("youtu.be/");

                // Extract the video ID if it's a YouTube URL
                const videoId = isYouTube
                  ? link.split("v=")[1]?.split("&")[0] || link.split("/").pop()
                  : null;

                // Construct the thumbnail URL for YouTube videos
                const thumbnailUrl = videoId
                  ? `https://img.youtube.com/vi/${videoId}/mqdefault.jpg` // Medium quality thumbnail
                  : brainFoundationLogo; // Default logo for non-YouTube links

                // Get the corresponding title
                const title = sources.titles[index] || "Unknown Title";

                const imageSizeClass = isYouTube ? "h-[70px] w-[200px]" : "h-[70px] w-[70px] ml-10";
                const titleSizeClass = !isYouTube ? "ml-8" : "";

                return (
                  <div
                    key={index}
                    className="mt-4 mx-auto flex items-center overflow-auto rounded-md border-0 h-25 md:h-25 lg:h-25 bg-white/5 px-5 py-5 text-slate-400 shadow-sm ring-1 text-sm md:text-lg ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  >
                    <div className="flex-col px-8 hidden sm:block">
                      <a href={link} target="_blank" rel="noopener noreferrer">
                        <img
                          className={`rounded-md object-cover ${imageSizeClass}`}
                          src={thumbnailUrl}
                          // width={80}
                          // height={80}
                          alt={
                            isYouTube
                              ? `Thumbnail for video ${videoId}`
                              : "Brain Foundation"
                          }
                        />
                      </a>
                    </div>
                    <div className="flex-col px-5">
                      <a
                        href={link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`text-indigo-400 hover:underline text-xs md:text-lg ${titleSizeClass}`}
                      >
                        {title}
                      </a>
                    </div>
                  </div>
                );
              })}
          {/* </div> */}
        </div>
      </div>

     
      
    </section>
  );
}
