export default function Footer() {
    return (
      <section className="mt-auto flex flex-col justify-between w-full isolate overflow-hidden bg-gray-900 py-8 sm:py-8 lg:py-8">
        <p className="mt-4 sm:px-20 text-xs md:px-40 md:leading-6 text-gray-300 px-5">
          This AI powered search engine helps you locate and find information from
          the BRAIN Foundation website and video content. Please note that this
          product is in beta and will continue to improve. This product is meant
          to provide easier access to information for the purpose of education and
          discussion. It does not provide medical advice. Please consult with your
          physicians for medical care.
        </p>
      </section>
    );
  }