import logo from './logo.svg';
import Navbar from './navbar';
import Home from './home';
import Footer from './footer';
import './App.css';

function App() {
  return (
    <div className="App flex flex-col min-h-screen">
      <Navbar />
      <div className="flex-grow">
        <Home />
      </div>
      <Footer />
    </div>
  );
}

export default App;
